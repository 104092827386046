import React from 'react';
import Navbar from "./HeroSection/Navbar";
import Footer from "./Footer/Footer";

// const termsData = [
//   {
//     heading: "Acceptance of Terms",
//     text: (
//       <>
//         <p>
//           1.1. By accessing or using the Service, you acknowledge that you have
//           read, understood, and agree to be bound by these Terms.
//         </p>
//         <p>
//           1.2. We may update these Terms from time to time. The revised Terms
//           will be effective immediately upon posting. Your continued use of the
//           Service after any such changes constitutes your acceptance of the new
//           Terms.
//         </p>
//       </>
//     ),
//   },
//   {
//     heading: "Description of Service",
//     text: (
//       <>
//         <p>
//           2.1. Alphaquark is a financial technology software designed to enable
//           financial advisors to send investment recommendations to their clients
//           and facilitate the execution of these recommendations.
//         </p>
//         <p>
//           2.2. The Service is currently in its launch stage. As such, it may
//           contain minor issues that could affect its
//           performance. We make no guarantees about the availability,
//           reliability, or accuracy of the Service.
//         </p>
//       </>
//     ),
//   },
//   {
//     heading: "User Responsibilities",
//     text: (
//       <>
//         <p>
//           3.1. You agree to use the Service only for lawful purposes and in
//           accordance with these Terms.
//         </p>
//         <p>
//           3.2. You must be at least 18 years old or have the consent of a parent
//           or guardian to use the Service.
//         </p>
//         <p>
//           3.3. You are responsible for maintaining the confidentiality of your
//           account information, including your username and password, and for all
//           activities that occur under your account.
//         </p>
//         <p>
//           3.4. You agree to notify us immediately of any unauthorized use of
//           your account or any other breach of security.
//         </p>
//       </>
//     ),
//   },
//   {
//     heading: "Subscription and Payment",
//     text: (
//       <>
//         <p>
//           4.1. The Service is offered on a subscription basis. Subscription fees
//           and payment terms will be specified at the time of purchase.
//         </p>
//         <p>
//           4.2. All payments are due in advance and are non-refundable except as
//           required by applicable law.
//         </p>
//         <p>
//           4.3. We reserve the right to change our fees at any time. Any changes
//           will be communicated to you in advance.
//         </p>
//       </>
//     ),
//   },
//   {
//     heading: "Limitation of Liability",
//     text: (
//       <>
//         <p>
//           5.1. To the fullest extent permitted by law, Arpint will not be liable
//           for any indirect, incidental, special, consequential, or punitive
//           damages, including but not limited to loss of profits, data, or other
//           intangible losses, arising out of or in connection with your use of
//           the Service.
//         </p>
//         <p>
//           5.2. Our total liability to you for any claims arising out of or in
//           connection with these Terms or your use of the Service will be limited
//           to the lesser of (i) the amount of monthly fees paid by you for the
//           Service during the month in which the claim arose, or (ii) INR
//           1,00,000.
//         </p>
//         <p>
//           5.3. We disclaim all warranties, whether express or implied, including
//           but not limited to warranties of merchantability, fitness for a
//           particular purpose, and non-infringement.
//         </p>
//       </>
//     ),
//   },
//   {
//     heading: "Indemnification",
//     text: (
//       <>
//         <p>
//           6.1. You agree to indemnify, defend, and hold harmless Arpint, its
//           affiliates, officers, directors, employees, and agents from and
//           against any claims, liabilities, damages, losses, or expenses,
//           including reasonable attorneys' fees, arising out of or in connection
//           with your use of the Service, your violation of these Terms, or your
//           infringement of any rights of another party.
//         </p>
//       </>
//     ),
//   },
//   {
//     heading: "Intellectual Property",
//     text: (
//       <>
//         <p>
//           7.1. All content, trademarks, and other intellectual property
//           associated with the Service are owned by Arpint or its licensors. You
//           may not use any of our intellectual property without our prior written
//           consent.
//         </p>
//         <p>
//           7.2. You retain ownership of any data you input into the Service, but
//           you grant us a license to use, reproduce, and analyze such data as
//           necessary to provide and improve the Service.
//         </p>
//       </>
//     ),
//   },
//   {
//     heading: "Privacy",
//     text: (
//       <>
//         <p>
//           8.1. Your use of the Service is also governed by our Privacy Policy,
//           which describes how we collect, use, and protect your personal
//           information.
//         </p>
//         <p>
//           8.2. By using the Service, you consent to the collection and use of
//           your information in accordance with our Privacy Policy.
//         </p>
//       </>
//     ),
//   },
//   {
//     heading: "Termination",
//     text: (
//       <>
//         <p>
//           9.1. We reserve the right to suspend or terminate your access to the
//           Service at our sole discretion, with or without cause, and with or
//           without notice.
//         </p>
//         <p>
//           9.2. You may terminate your subscription at any time by following the
//           instructions provided in the Service. No refunds will be issued for
//           any unused portion of the subscription term.
//         </p>
//       </>
//     ),
//   },
//   {
//     heading: "Governing Law and Dispute Resolution",
//     text: (
//       <>
//         <p>
//           10.1. These Terms are governed by and construed in accordance with the
//           laws of India.
//         </p>
//         <p>
//           10.2. Any disputes arising out of or in connection with these Terms or
//           your use of the Service will be subject to the exclusive jurisdiction
//           of the courts located in Bengaluru, India.
//         </p>
//       </>
//     ),
//   },
//   {
//     heading: "Miscellaneous",
//     text: (
//       <>
//         <p>
//           11.1.
//           <strong>Severability</strong>: If any provision of these Terms is
//           found to be invalid or unenforceable, the remaining provisions will
//           continue in full force and effect.
//         </p>
//         <p>
//           11.2.
//           <strong>No Waiver</strong>: Our failure to enforce any right or
//           provision of these Terms will not be deemed a waiver of such right or
//           provision.
//         </p>
//         <p>
//           11.3.
//           <strong>Assignment</strong>: We may assign these Terms, in whole or in
//           part, at any time without notice to you. You may not assign these
//           Terms without our prior written consent.
//         </p>
//         <p>
//           11.4.
//           <strong> Entire Agreement</strong>: These Terms constitute the entire
//           agreement between you and us regarding your use of the Service and
//           supersede all prior or contemporaneous agreements and understandings.
//         </p>
//         <p>
//           11.5.
//           <strong>Contact Us</strong>: If you have any questions about these
//           Terms, please contact us at{" "}
//           <a
//             href="mailto:pratik@alphaquark.in"
//             className="text-blue-500 underline"
//           >
//             pratik@alphaquark.in
//           </a>
//         </p>
//       </>
//     ),
//   },
// ];

// const TermsOfServices = () => {
//   return (
//     <div className={`bg-[#000000]`}>
//       <Navbar />
//       <div className="bg-[#1b1b1b] py-24 md:py-36 px-8 md:px-20">
//         <div className="flex flex-row justify-center text-white text-3xl md:text-5xl font-medium font-poppins">
//           Terms of Service
//         </div>

//         <div className="mt-12 text-white text-base md:text-lg font-medium font-poppins">
//           Last Updated: 1 September 2024
//         </div>

//         <div className="mt-4 text-white text-base md:text-lg font-medium font-poppins">
//           Welcome to Alphaquark, a software product developed by Arpint Private
//           Ltd ("Arpint", "we", "our", or "us"). By accessing or using our
//           software and services (collectively, the "Service"), you agree to
//           comply with and be bound by the following terms and conditions (the
//           "Terms"). If you do not agree to these Terms, please do not use our
//           Service.
//         </div>

//         {termsData.map((term, index) => (
//           <div key={index}>
//             <div className="mt-12 text-white text-xl md:text-2xl font-medium font-poppins">
//               {term.heading}
//             </div>
//             <div className="mt-4 text-white text-base md:text-lg font-medium font-poppins">
//               {term.text}
//             </div>
//           </div>
//         ))}

//         <div className="mt-8 text-white text-base md:text-lg font-medium font-poppins">
//           By using Alphaquark, you agree to abide by these terms of service.
//         </div>
//       </div>
//       <Footer />
//     </div>
//   );
// };

// export default TermsOfServices;



const termsData = [
  {
    heading: "Acceptance of Terms",
    text: [
      "1.1. By accessing or using the Service, you acknowledge that you have read, understood, and agree to be bound by these Terms.",
      "1.2. We may update these Terms from time to time. The revised Terms will be effective immediately upon posting. Your continued use of the Service after any such changes constitutes your acceptance of the new Terms."
    ]
  },
  {
    heading: "Description of Service",
    text: [
      "2.1. Alphaquark is a financial technology software designed to enable financial advisors to send investment recommendations to their clients and facilitate the execution of these recommendations.",
      "2.2. The Service is currently in its launch stage. As such, it may contain minor issues that could affect its performance. We make no guarantees about the availability, reliability, or accuracy of the Service."
    ]
  },
  {
    heading: "User Responsibilities",
    text: [
      "3.1. You agree to use the Service only for lawful purposes and in accordance with these Terms.",
      "3.2. You must be at least 18 years old or have the consent of a parent or guardian to use the Service.",
      "3.3. You are responsible for maintaining the confidentiality of your account information, including your username and password, and for all activities that occur under your account.",
      "3.4. You agree to notify us immediately of any unauthorized use of your account or any other breach of security."
    ]
  },
  {
    heading: "Subscription and Payment",
    text: [
      "4.1. The Service is offered on a subscription basis. Subscription fees and payment terms will be specified at the time of purchase.",
      "4.2. All payments are due in advance and are non-refundable except as required by applicable law.",
      "4.3. We reserve the right to change our fees at any time. Any changes will be communicated to you in advance."
    ]
  },
  {
    heading: "Limitation of Liability",
    text: [
      "5.1. To the fullest extent permitted by law, Arpint will not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, or other intangible losses, arising out of or in connection with your use of the Service.",
      "5.2. Our total liability to you for any claims arising out of or in connection with these Terms or your use of the Service will be limited to the lesser of (i) the amount of monthly fees paid by you for the Service during the month in which the claim arose, or (ii) INR 1,00,000.",
      "5.3. We disclaim all warranties, whether express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement."
    ]
  },
  {
    heading: "Indemnification",
    text: [
      "6.1. You agree to indemnify, defend, and hold harmless Arpint, its affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, or expenses, including reasonable attorneys' fees, arising out of or in connection with your use of the Service, your violation of these Terms, or your infringement of any rights of another party."
    ]
  },
  {
    heading: "Intellectual Property",
    text: [
      "7.1. All content, trademarks, and other intellectual property associated with the Service are owned by Arpint or its licensors. You may not use any of our intellectual property without our prior written consent.",
      "7.2. You retain ownership of any data you input into the Service, but you grant us a license to use, reproduce, and analyze such data as necessary to provide and improve the Service."
    ]
  },
  {
    heading: "Privacy",
    text: [
      "8.1. Your use of the Service is also governed by our Privacy Policy, which describes how we collect, use, and protect your personal information.",
      "8.2. By using the Service, you consent to the collection and use of your information in accordance with our Privacy Policy."
    ]
  },
  {
    heading: "Termination",
    text: [
      "9.1. We reserve the right to suspend or terminate your access to the Service at our sole discretion, with or without cause, and with or without notice.",
      "9.2. You may terminate your subscription at any time by following the instructions provided in the Service. No refunds will be issued for any unused portion of the subscription term."
    ]
  },
  {
    heading: "Governing Law and Dispute Resolution",
    text: [
      "10.1. These Terms are governed by and construed in accordance with the laws of India.",
      "10.2. Any disputes arising out of or in connection with these Terms or your use of the Service will be subject to the exclusive jurisdiction of the courts located in Bengaluru, India."
    ]
  },
  {
    heading: "Miscellaneous",
    text: [
      "11.1. Severability: If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will continue in full force and effect.",
      "11.2. No Waiver: Our failure to enforce any right or provision of these Terms will not be deemed a waiver of such right or provision.",
      "11.3. Assignment: We may assign these Terms, in whole or in part, at any time without notice to you. You may not assign these Terms without our prior written consent.",
      "11.4. Entire Agreement: These Terms constitute the entire agreement between you and us regarding your use of the Service and supersede all prior or contemporaneous agreements and understandings.",
      "11.5. Contact Us: If you have any questions about these Terms, please contact us at <a href=\"mailto:pratik@alphaquark.in\" style=\"color: #3b82f6; text-decoration: underline;\">pratik@alphaquark.in</a>"
    ]
  }
]

export default function TermsOfService() {
  return (
    <div className="min-h-screen bg-black text-white ">
      <Navbar />
      <main className="bg-zinc-900 py-12 md:py-24 px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto pt-8 sm:pt-0">
          <h1 className="text-3xl md:text-5xl font-bold text-center mb-8">Terms of Service</h1>
          <p className="text-lg mb-4">Last Updated: 17 october 2024</p>
          <p className="text-lg mb-12">
            Welcome to Alphaquark, a software product developed by Arpint Private Ltd ("Arpint", "we", "our", or "us"). 
            By accessing or using our software and services (collectively, the "Service"), you agree to comply with and 
            be bound by the following terms and conditions (the "Terms"). If you do not agree to these Terms, please do 
            not use our Service.
          </p>
          {termsData.map((term, index) => (
            <section key={index} className="mb-12">
              <h2 className="text-2xl font-semibold mb-4">{term.heading}</h2>
              {term.text.map((paragraph, pIndex) => (
                <p 
                  key={pIndex} 
                  className="mb-4 text-base md:text-lg" 
                  dangerouslySetInnerHTML={{ __html: paragraph }}
                />
              ))}
            </section>
          ))}
          <p className="text-lg font-semibold mt-8">
            By using Alphaquark, you agree to abide by these terms of service.
          </p>
        </div>
      </main>
      <Footer />
    </div>
  )
}