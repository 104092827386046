import React from 'react';
import Navbar from "./HeroSection/Navbar";
import Footer from "./Footer/Footer";



export default function RefundCancellation() {
    return (
      <div className="min-h-screen bg-black text-white">
        <Navbar />
        <main className="bg-zinc-900 py-12 md:py-24 px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto pt-8 sm:pt-0">
            <h1 className="text-3xl md:text-5xl font-bold text-center mb-8">Return and Cancellation Policy</h1>
            <div className="space-y-6 text-lg">
              <p>
                At AlphaQuark, we maintain a no return, no refund, and no cancellation policy for all purchases. 
                This policy reflects our commitment to delivering high-quality, non-tangible products and services. 
                Once a transaction is completed, the sale is considered final.
              </p>
              <p>
                We encourage customers to carefully review product descriptions and system requirements prior to 
                making a purchase. We also recommend taking advantage of any free trials or demos we may offer 
                from time to time, allowing you to evaluate our software before committing to a purchase.
              </p>
              <p>
                While we do not offer refunds, we stand by the quality of our products and are dedicated to 
                assisting customers with any technical issues or concerns that may arise after purchase.
              </p>
            </div>
        
            <div className="mt-12 bg-zinc-800 p-6 rounded-lg">
              <h2 className="text-xl font-semibold mb-4">Need Assistance?</h2>
              <p className="mb-4">
                If you have any questions about our Return and Cancellation Policy or need technical support, 
                please don't hesitate to contact our customer service team.
              </p>
              <a 
                href="mailto:pratik@alphaquark-case.com" 
                className="inline-block bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
              >
                Contact Support
              </a>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    )
  }