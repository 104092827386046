import React from "react";
import Navbar from "./HeroSection/Navbar";
import Footer from "./Footer/Footer";

const Disclaimer = () => {
  return (

    <div className="min-h-screen bg-black text-white">
    <Navbar />
    <main className="bg-zinc-900 py-12 md:py-24 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto  pt-8 sm:pt-0">
        <h1 className="text-3xl md:text-5xl font-bold text-center mb-12">Disclaimer</h1>
        <div className="space-y-8 text-lg">
          <p>
          Alphaquark, developed by Arpint Pvt Ltd, is a financial technology
            software offered to advisors. The information and
            recommendations provided through our Service are for informational
            purposes only and should not be considered financial advice. Please do
            your own research and/or consult a SEBI-registered expert (e.g., RIA)
            before making any investment decisions.
          </p>
          <p>
          Arpint Pvt Ltd, as a technology service provider, makes no guarantees
            regarding the accuracy, reliability, or completeness of the
            information or functionality of the software and shall not be liable
            for any direct, indirect, incidental, or consequential damages arising
            from its use. 
    
          </p>
          <p>
          All financial investments are subject to market risks;
            please read all scheme-related documents carefully. Our liability is
            capped at the lesser of the amount of monthly fees paid or INR
            1,00,000, and all disputes will be governed by the laws of India, with
            jurisdiction exclusively in the courts of Bengaluru. 
          </p>
          <p className="font-semibold">
          By using our
          Service, you acknowledge and accept these terms. </p>
        </div>
      
      </div>
    </main>
    <Footer />
  </div>
  );
};

export default Disclaimer;