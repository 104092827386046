import React from "react";

import Navbar from "./HeroSection/Navbar";
import styles from "./HeroSection/heroSection.module.css";

import Effeciency from "./assests/Effeciency.svg";
import Emotion from "./assests/Emotion.svg";
import Accessibility from "./assests/Accesibility.svg";
import AdvisorSolution from "./whatSetsApart/AdvisorSolutions";
import LoginRegister from "./common/LoginRegister";

const style = {
  selectedDiv:
    "px-2 md:px-12 text-white font-sans font-bold text-sm md:text-[20px] md:leading-[46px] cursor-pointer border-b-2 border-[#01B0EA] md:pb-6 ",
  unSelectedDiv:
    "px-2 md:px-12 text-[#ffffff]/50 font-sans font-bold text-sm md:text-[20px] md:leading-[46px] cursor-pointer border-b-[1px] border-[#ffffff]/10 md:pb-6",
  tradingHeading: "text-xl md:text-2xl text-white font-sans font-bold",
  tradingDescription: "text-sm md:text-base text-[#ffffff]/50 font-poppins",
  flexDiv: "flex flex-row justify-center items-center",
};

const AdvisorData = [
  {
    text: "Trade Strategy",
    desc: "Sign up for best trading strategies and investment advice as provided by SEBI registered advisors.",
  },
  {
    text: "Advice Execution",
    desc: "Review, approve and execute advisor’s recommendation seamlessly and capture best value.",
  },
  {
    text: "Notifications",
    desc: "Receive instant notifications through whatsapp, email, telegram and sms.",
  },
  {
    text: "Analytics",
    desc: "Deep IRR insights, risk exposure at multiple levels of aggregation.",
  },
  {
    text: "Investment Research",
    desc: "Unique insights through synthesis of financial news, social sentiment and macro economic drivers.",
  },
  {
    text: "Data Security",
    desc: "Highest standards of data security and world class certifications.",
  },
];
const Investors = () => {
  return (
    <div className={`bg-[#1b1b1b] `}>
      <Navbar />

      <div className={`bg-[#1b1b1b] `}>
        <div className="py-20 md:py-24" id="automation">
          <div className="flex flex-col space-y-4 lg:space-y-2 justify-center items-center">
            {" "}
            <div
              className={`font-sans text-md md:text-xl font-semibold ${styles.text_gradient}`}
            >
              BENEFITS
            </div>
            <div className="font-extrabold text-white text-[18px]  md:text-[38px] md:leading-[60px] font-sans">
              Automate to Elevate !
            </div>
          </div>

          <div
            className={`mt-20 flex flex-col space-y-10 md:space-y-0 md:flex-row justify-between items-center px-4 md:px-10 lg:px-48`}
          >
            <div className="relative md:w-[30%] px-10 md:px-1 flex flex-col space-y-4  md:h-[350px] ">
              <div className="flex items-center justify-center lg:justify-start ">
                <img
                  className=" w-[100px]  transition duration-150 ease-linear"
                  src={Effeciency}
                  alt="Effecifiency"
                />
                <div className="absolute w-[0%] md:w-[60%] h-[1px] bg-[#ffffff]/10 lg:-right-[20px]"></div>
              </div>
              <div className="flex flex-1 flex-col space-y-4  ">
                <div
                  className={` w-full text-base  md:text-[22px] text-center md:leading-[30px] lg:text-left lg:leading-[50px] ${style.tradingHeading} `}
                >
                  Trusted advice
                </div>
                <div
                  className={` w-full  text-xs md:text-[16px] leading-[26px] text-center lg:text-left  ${style.tradingDescription}`}
                >
                  Gain peace of mind with our platform's expert investment
                  guidance certified advisors you can trust.
                </div>
              </div>
            </div>

            <div className="relative  md:w-[30%] px-10 md:px-1 flex flex-col space-y-4  md:h-[350px] ">
              <div className="flex  items-center justify-center lg:justify-start ">
                <img
                  className=" w-[100px] transition duration-150 ease-linear"
                  src={Emotion}
                  alt="Speed"
                />
                <div className="absolute w-[0%] md:w-[60%] h-[1px] bg-[#ffffff]/10  lg:-right-[20px]"></div>
              </div>

              <div className="flex flex-1 flex-col space-y-4 ">
                <div
                  className={`w-full text-base  md:text-[22px] text-center md:leading-[30px] lg:text-left lg:leading-[50px] ${style.tradingHeading} `}
                >
                  Automation
                </div>
                <div
                  className={`text-xs md:text-[16px] leading-[26px]  md:w-[95%] text-center lg:text-left ${style.tradingDescription}`}
                >
                  Streamline your investments, connect with industry leading SEBI
                  registered Investment advisors and Research Analysts to
                  seize every opportunity, and save time
                  with our single click investment technology.
                  Do you use intercom in the mygate world, if not, switch to investing with our leading advisors.
                </div>
              </div>
            </div>

            <div className="md:w-[30%] px-10 md:px-1 flex flex-col space-y-4   md:h-[350px] ">
              <div className="flex items-center justify-center lg:justify-start ">
                <img
                  className=" w-[100px]  transition duration-150 ease-linear"
                  src={Accessibility}
                  alt="Accessibility}"
                />
              </div>
              <div className="flex flex-1  flex-col space-y-4 ">
                <div
                  className={`w-full text-base  md:text-[22px] text-center md:leading-[30px] lg:text-left lg:leading-[50px] ${style.tradingHeading} `}
                >
                  Market signals
                </div>
                <div
                  className={`text-xs md:text-[16px] leading-[26px]  md:w-[90%] text-center lg:text-left ${style.tradingDescription}`}
                >
                  Unlock actionable data backed insights empowering informed
                  trading decisions.
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-center mt-24">
            <LoginRegister />
          </div>
        </div>
      </div>
      <div className="py-20 md:py-24" id="features">
        <div className="flex flex-col space-y-4 lg:space-y-2 justify-center items-center">
          {" "}
          <div
            className={`font-sans text-md md:text-xl font-semibold ${styles.text_gradient}`}
          >
            SOLUTIONS
          </div>
          <div
            className={`text-center font-extrabold text-white text-[18px] md:text-[38px] md:leading-[60px] font-sans`}
          >
            One Partner , Endless Solutions
          </div>
        </div>

        <AdvisorSolution data={AdvisorData} />
        <div className="flex flex-row justify-center mt-20">
          <LoginRegister />
        </div>
      </div>
    </div>
  );
};

export default Investors;
