import React from "react";

import Zerodha from "../assests/broker/Zerodha.svg";
import AngelOne from "../assests/broker/AngelOne.svg";
import IIFL from "../assests/broker/IFFL.svg";
import Icici from "../assests/broker/ICICIDirect.svg";
import Kotak from "../assests/broker/Kotak.svg";
import Upstox from "../assests/broker/Upstox.svg";
import Fyers from "../assests/broker/Fyers.svg";
import Hdfc from "../assests/broker/hdfc.jpg";
import Dhan from "../assests/broker/dhan.png";
import AliceBlue from "../assests/broker/AliceBlue.svg";

const indiaMarket = [
  {
    broker: "Zerodha",
    imageURL: Zerodha,
  },
  {
    broker: "Angel One",
    imageURL: AngelOne,
  },
  {
    broker: "IIFL Securities",
    imageURL: IIFL,
  },
  {
    broker: "Icici",
    imageURL: Icici,
  },
  {
    broker: "Kotak",
    imageURL: Kotak,
  },
  {
    broker: "Upstox",
    imageURL: Upstox,
  },
  {
    broker: "Fyers",
    imageURL: Fyers,
  },
  {
    broker: "Dhan",
    imageURL: Dhan,
  },
  {
    broker: "Alice Blue",
    imageURL: AliceBlue,
  },
  {
    broker: "Hdfc",
    imageURL: Hdfc,
  },

 


];

const SolutionOne = () => {
  return (
//     <div className="">
//       <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-2  lg:gap-y-2 lg:gap-x-2 ">
//         {indiaMarket.map((ele, i) => {

//           return (
//             // <div
//             //   className="bg-[#ffffff] flex flex-row items-center mt-2 mr-2 px-8 py-3 rounded-md"
//             //   key={i}
//             // >
//             <div
//             className={`bg-[#ffffff] flex flex-row items-center mt-2 mr-2 rounded-md ${
//               ele.broker === "Hdfc" ? "px-8 py-[20px]" : 
//               ele.broker === "Dhan" ? "px-8 py-1" : 
//               ele.broker === "Alice Blue" ? "px-8 py-0.5" : 

//               "px-8 py-3"
//             }`}
//             key={i}
//           >
//               <img src={ele.imageURL} alt={ele.imageURL} 
//               className="w-32" />
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// };

<div className="px-2 md:px-0">
<div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-2 lg:gap-y-2 lg:gap-x-2">
  {indiaMarket.map((ele, i) => {
    return (
      <div
        className={`bg-[#ffffff] flex flex-row items-center justify-center mt-2 rounded-md h-14 md:h-auto ${
          ele.broker === "Hdfc" ? "px-2 md:px-8 md:py-[20px]" : 
          ele.broker === "Dhan" ? "px-2 md:px-8 md:py-1" : 
          ele.broker === "Alice Blue" ? "px-2 md:px-8 md:py-0.5" : 
          "px-2 md:px-8 md:py-3"
        }`}
        key={i}
      >
        <img 
          src={ele.imageURL} 
          alt={ele.imageURL} 
          className="w-full max-w-[100px] md:max-w-[128px] md:w-32 object-contain"
        />
      </div>
    );
  })}
</div>
</div>
);
};

export default SolutionOne;
