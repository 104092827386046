import React from 'react';
import Navbar from "./HeroSection/Navbar";
import Footer from "./Footer/Footer";


const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-black text-white">
    <Navbar />
    <main className="bg-zinc-900 py-12 md:py-24 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto pt-8 sm:pt-0">
         <h1 className="text-3xl md:text-5xl font-bold text-center mb-8">Privacy Policy</h1>
        <p className="text-lg mb-6">Last Updated: 17 october 2024</p>
        <p className="text-lg mb-8">
          Welcome to AlphaQuark, a product of Arpint Private Ltd ("Arpint", "we", "our", or "us"). This Privacy Policy describes how we collect, use, disclose, and protect your personal information when you use our software and services (collectively, the "Service"). By using our Service, you consent to the practices described in this Privacy Policy.
        </p>
        <ol className="space-y-8 list-decimal list-inside">
          {policyItems.map((item, index) => (
            <li key={index} className="text-xl font-semibold">
              {item.title}
              <div className="mt-2 text-base font-normal">
                {item.content}
                {item.subItems && (
                  <ul className="mt-4 space-y-4 list-disc list-inside pl-4">
                    {item.subItems.map((subItem, subIndex) => (
                      <li key={subIndex} className="text-lg">
                        <span className="font-semibold">{subItem.title}</span>
                        <p className="mt-1">{subItem.content}</p>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </li>
          ))}
        </ol>
      </div>
    </main>
    <Footer />
  </div>
)
}

const policyItems = [
{
  title: "Information We Collect",
  subItems: [
    {
      title: "Personal Information",
      content: "We may collect personal information that you provide directly to us, such as your name, email address, phone number, financial information, and other details necessary for you to use our Service."
    },
    {
      title: "Usage Data",
      content: "We automatically collect information about your interactions with the Service, including IP addresses, browser types, operating systems, pages visited, and the time spent on the Service. This data helps us understand how users engage with our Service and improve its performance."
    },
    {
      title: "Cookies and Tracking Technologies",
      content: "We use cookies and similar tracking technologies to enhance your experience on our Service. Cookies are small files stored on your device that help us remember your preferences and provide a more personalized experience. You can control cookies through your browser settings, but disabling cookies may affect the functionality of the Service."
    }
  ]
},
{
  title: "How We Use Your Information",
  subItems: [
    {
      title: "To Provide and Improve the Service",
      content: "We use your information to deliver, maintain, and improve the Service. This includes processing your requests, sending you updates, and ensuring that the Service operates smoothly."
    },
    {
      title: "To Communicate with You",
      content: "We may use your contact information to communicate with you about your account, respond to your inquiries, and provide you with important information regarding the Service."
    },
    {
      title: "For Analytics and Research",
      content: "We use aggregated and anonymized data to analyze usage patterns and improve the Service. This helps us understand user behavior and make data-driven decisions."
    },
    {
      title: "For Marketing and Promotional Purposes",
      content: "With your consent, we may use your information to send you promotional materials and updates about our products and services. You can opt-out of receiving these communications at any time by following the instructions provided in the communication."
    }
  ]
},
{
  title: "How We Share Your Information",
  subItems: [
    {
      title: "Service Providers",
      content: "We may share your information with third-party service providers who assist us in operating the Service, such as hosting providers, payment processors, and email service providers. These third parties are contractually obligated to protect your information and use it only for the purposes for which it was shared."
    },
    {
      title: "Legal Requirements",
      content: "We may disclose your information if required to do so by law or in response to valid requests by public authorities, such as in connection with legal proceedings, regulatory requirements, or to protect our rights, property, or safety."
    },
    {
      title: "Business Transfers",
      content: "In the event of a merger, acquisition, or any form of asset sale, your information may be transferred as part of the business transaction. We will notify you of any such change in ownership or control of your information."
    }
  ]
},
{
  title: "Data Security",
  subItems: [
    {
      title: "Protection Measures",
      content: "We implement reasonable security measures to protect your information from unauthorized access, use, or disclosure. This includes using encryption, secure servers, and access controls."
    },
    {
      title: "Limitations",
      content: "Despite our efforts, no security system is impenetrable. We cannot guarantee the security of your information transmitted to or from our Service, and you use the Service at your own risk."
    }
  ]
},
{
  title: "Your Rights and Choices",
  subItems: [
    {
      title: "Access and Correction",
      content: "You have the right to access and update your personal information. If you need to correct or update your information, please contact us at hello@alphaquark.in"
    },
    {
      title: "Data Deletion",
      content: "You may request the deletion of your personal information. We will comply with such requests, subject to any legal or contractual obligations we may have."
    },
    {
      title: "Opt-Out",
      content: "You can opt-out of receiving marketing communications from us by following the unsubscribe instructions provided in those communications or by contacting us directly."
    }
  ]
},
{
  title: "Data Retention",
  content: "We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, to comply with legal obligations, or to enforce our agreements. When your information is no longer needed, we will securely delete or anonymize it."
},
{
  title: "Third-Party Links",
  content: "Our Service may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third parties. We encourage you to review the privacy policies of any third-party sites you visit."
},
{
  title: "Children's Privacy",
  content: "Our Service is not intended for children under the age of 18. We do not knowingly collect or solicit personal information from children. If we become aware that we have collected personal information from a child without parental consent, we will take steps to delete such information."
},
{
  title: "International Transfers",
  content: "If you are located outside India, please be aware that your information may be transferred to and processed in India. By using our Service, you consent to the transfer of your information to India and other countries where our service providers may operate."
},
{
  title: "Changes to This Privacy Policy",
  content: "We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information."
},
{
  title: "Contact Us",
  content: "If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at hello@alphaquark.in"
},
{
  title: "Limitation of Liability",
  content: "To the fullest extent permitted by law, Arpint will not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, or other intangible losses, arising out of or in connection with our handling of your personal information."
}
]

export default PrivacyPolicy;