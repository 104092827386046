import React from "react";

const LoginRegister = () => {
  return (
    <a
      href="https://test.alphaquark.in/user"
      target="_blank"
      rel="noreferrer noopener"
    >
      <button className="shine text-white font-medium font-poppins w-[200px] h-[45px] px-4 py-2 md:h-[68px] md:w-[300px] text-[16px] md:text-[22px] md:px-16 md:py-3 gradient-blue rounded-[12px] hover:scale-105 active:scale-95">
        Login/Register
      </button>
    </a>
  );
};

export default LoginRegister;
